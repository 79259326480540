.foots {
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    color: black;
}

.footer1 {
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    align-items: center;
    padding: 10px 20px;
    width: 100%;
    height: 20px;
    background-color: rgba(255, 255, 255, 0.5);
    color: black;
}

.foot1 {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.foot1 > p {
    font-size: 15px;
    /* font-weight: bold */
}

.foot1 > img {
    border-radius: 100%;
    width: 20px;
    height: 20px;
    display: flex;
    align-self: center;
}


.about {
    display: flex;
    flex-direction: row;
    gap: 10px;
    font-size: 15px;
    /* font-weight: bold; */
    justify-content: center; /* Center-align the contents */
}

.about-info{
    text-decoration: none;
}

.icons, .fab, .fa {
    color: black;
    text-decoration: none;
    padding-left: 5px;
}

.anti-link {
    text-decoration: none;
}
