/* Get All Articles CSS *************************/

.article-background {
    background-image: url('https://groundedfullstack.s3.amazonaws.com/grounded_product_images/ArticleBackgroundPage.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    min-height: 100vh;

}

.all-articles-container{
    padding-top: 100px;
    padding-left: 4%;
    padding-right: 4%;
    padding-bottom: 6%;
}

.arrangement-of-articles{
    /* border: solid; */
    display: grid;
    grid-template-rows: 1fr;
    column-gap: 20px;
    row-gap: 20px;
    text-decoration: none;
}

.article-edit-container-present{
    padding-bottom: 20px;
    display: inline-block;
}

.article-edit-button{
    height: 40px;
    width: 150px;
    border-radius: 50px;
    font-size: 15px;
    background-color: #fff9f0;
    border-color: #41483b;
}

.article-edit-button:hover {
    cursor: pointer;
    background-color: #41483b;
    color: white;
}

/* Each Article CSS ******************************/

.main-single-article-container{
    /* border: solid red; */
    background-color: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 200px;
    text-align: center;
    padding-top: 20px;
    text-decoration: none;
    display: grid;
    grid-template-rows: 1fr 1fr 3fr auto;
}

.each-article-name{
    font-size: 30px;
    text-decoration: none;
}

.each-article-description{
    padding-top: 20px;
    font-size: 18px;
    white-space: nowrap; /* Prevent text from wrapping to the next line */
    overflow: hidden; /* Hide any overflowing text */
    text-overflow: ellipsis; /* Display ellipsis (...) for truncated text */
    max-width: 100%; /* Ensure text doesn't overflow the container */
    padding-left: 20px;
    padding-right: 20px;
}

.each-article-more{
    align-self: end;
    padding-bottom: 20px;
    text-decoration: underline;
    cursor: pointer;
}

a {
    text-decoration: none;
    color: black;
}

/* Each Article by Id CSS *************************/
.each-article-by-id-background {
    background-image: url('https://groundedfullstack.s3.amazonaws.com/grounded_product_images/ArticleBackgroundPage.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    min-height: 100vh;

}

.each-article-by-id-return-to-all-article-button{
    padding-top: 100px;
    padding-left: 4%;
    padding-right: 4%;
}

.main-single-article-id-container{
    padding: 20px 4% 20px 4%;
    background-color: rgba(255, 255, 255, 0.5);
    text-align: center;
    text-decoration: none;
    display: grid;
    grid-template-rows: 1fr 1fr 3fr auto;

}

.each-article-id-description{
    padding-top: 20px;
    font-size: 18px;
    /* max-width: 100%; */
}

.each-article-id-more{
    align-self: end;
    padding-bottom: 20px;
    text-decoration: underline;
    cursor: pointer;
    padding-top: 20px;
}

/* User Articles CSS ******************************/
a.nav-link-articles{
    display: inline-block;
}

.article-edit-container-user{
    padding-bottom: 20px;
    cursor: pointer;
    display: flex;
    flex-direction: column;

}

.list-another-article{
    text-decoration: underline;
    display: inline-block;
}

.each-article-buttons{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-self: center;
    justify-self: center;
    column-gap: 20px;
}

.user-article-edit-delete-buttons{
    height: 40px;
    width: 150px;
    border-radius: 50px;
    font-size: 15px;
    background-color: #fff9f0;
    border-color: #41483b;
}

.user-article-edit-delete-buttons:hover {
    cursor: pointer;
    background-color: #41483b;
    color: white;
}

/* Edit Article CSS *************************/
.create-new-article-form-container{
    background: url(https://groundedfullstack.s3.amazonaws.com/grounded_product_images/ArticleBackgroundPage.jpg) no-repeat fixed center;
    /* background-repeat: no-repeat; */
    /* background-attachment: fixed; */
    background-size: cover;
    padding-top: 100px;
    padding-left: 1.5%;
    padding-bottom: 160px;
    min-height: 100vh;

    display: grid;
    grid-template-columns: 1fr 3fr;
}

.create-new-article-form-submit-button{
    height: 55px;
    width: 130px;
    border-radius: 50px;
    font-size: 15px;
    background-color: none;
    cursor: pointer;
}

.create-new-article-form-submit-button:hover {
    cursor: pointer;
    background-color: #41483b;
    color: white;
}

.create-new-article-form{
    padding-top: 80px;
    justify-self: center;
    justify-content: center;
    justify-items: center;

}

.create-new-article-form-details{
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;

    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 30px;
    padding: 20px;

}

.article-all-input-boxes{
    height: 50px;
    width: 350px;
    border-radius: 30px;
    text-align: center;
}

.article-all-input-boxes-text{
    height: 50px;
    width: 350px;
    border-radius: 30px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
}

.article-errors{
    text-align: center;
    color: red;
    font-size: 12pt;
}
