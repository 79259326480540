/* Get All Products CSS *************************/

.all-products-container{
    padding-top: 100px;
    padding-left: 4%;
    padding-right: 4%;
    padding-bottom: 6%;
}

.product-page-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;

}

.product-link{
    text-decoration: none;
    color: rgb(65,72,59)
    ;
}

/* Each Product CSS ******************************/

.main-single-product-container{
    padding-top: 70px;
}

.each-product-preview-image{
    width: 100%;
    height: 180%;
}

.each-product-name-price{
    text-align: center;
}

.each-product-name{
    font-size: 13pt;
    font-weight: bolder;
}

.each-product-price{
    font-size: 12pt;
    padding-top: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 100;
}

.each-product-name {
    text-decoration: none; /* Remove the underline */
    /* Any other styling you want to apply */
}

/* Product Detail Page CSS ******************************/

.product-detail-image-container {
    display: flex;
    padding-top: 90px;
}

.product-detail-description {
    flex: 1;
    padding-left: 4%;
    box-sizing: border-box;
    padding-top: 90px;
}

.product-detail-page-item-name{
    font-size: 38px;
    font-weight: 100;
    padding-bottom: 50px;

}

.product-detail-page-item-price{
    font-size: 25px;
    font-weight: lighter;
    padding-bottom: 50px;
}

.product-detail-page-item-description{
    font-size: 18px;
    font-weight: lighter;
    padding-bottom: 50px;
}

.product-detail-page-item-dimension{
    padding-bottom: 50px;
}

.PID-about-product-div{
    font-style: oblique;
}

.log-signup-link{
    text-decoration: none;
    color: black;
    text-decoration: underline;
}

.log-signup-link:hover{
    color: #88896f;
}

.product-detail-image {
    flex: 1;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-by-id-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.add-to-cart-button {
    height: 40px;
    width: 150px;
    border-radius: 50px;
    font-size: 15px;
    background-color: #fff9f0;
    border-color: #41483b;
}

.add-to-cart-button:hover {
    cursor: pointer;
    background-color: #41483b;
    color: white;
}

.product-detail-description-bottom{
    padding-left: 4%;
}

.product-detail-page-item-bottom-description-title{
    font-size: 25px;
    font-weight: lighter;
    padding-bottom: 30px;
}

.product-detail-page-item-bottom-description{
    font-style: oblique;
    padding-bottom: 6%;
}


/* Get User Products CSS ******************************/

.user-selling-blurb{
    font-size: 30px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #88896f;
}

.user-selling-new-and-filter{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.list-another-plant-to-sell{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #856055;
    text-decoration: underline;
    cursor: pointer;
}

.filter-link{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #856055;
    cursor: pointer;
}

.edit-delete-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.edit-delete-links{
    padding-top: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #856055;
    cursor: pointer;
    text-decoration: underline;

}


/* Create New Product Form CSS ******************************/

.create-new-product-form-container{
    background: url(https://groundedfullstack.s3.amazonaws.com/grounded_product_images/background-img.jpeg) no-repeat fixed center;
    /* background-repeat: no-repeat; */
    /* background-attachment: fixed; */
    background-size: cover;
    padding-top: 100px;
    padding-left: 1.5%;
    padding-bottom: 160px;

    display: grid;
    grid-template-columns: 1fr 3fr;
}

.create-new-product-form-submit-button{
    height: 55px;
    width: 130px;
    border-radius: 50px;
    font-size: 15px;
    background-color: none;
}

.create-new-product-form-submit-button {
    cursor: pointer;
}

.create-new-product-form{
    padding-top: 80px;
    justify-self: center;
    justify-content: center;
    justify-items: center;

}

.create-new-product-form-details{
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;

}

.all-input-boxes{
    height: 50px;
    width: 350px;
    border-radius: 50px;
    text-align: center;
}

.errors{
    text-align: center;
    color: red;
    font-size: 9pt;
}


.create-new-product-product-dimension-container{
    display: flex;
}

.all-input-boxes-drop-box{
    height: 50px;
    width: 175px;
    border-radius: 50px;
    text-align: center;
}


/* Delete user item modal CSS ***************************** */

.modal-content-cart-delete{
    width: 350px;
    height: 100px;
    border-radius: 10px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}


#delete-user-item-modal-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

}


.edit-user-item-info{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px #0000001a;
    row-gap: 10px;
}

.from-user-action{
    height: 40px;
    width: 150px;
    border-radius: 50px;
    font-size: 15px;
    background-color: #fff9f0;
    border-color: #41483b;
}

.from-user-action:hover {
    cursor: pointer;
    background-color: #41483b;
    color: white;
}




/*
.modal-content-cart-delete{
    width: 350px;
    height: 100px;
    border-radius: 10px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}


#delete-user-item-modal-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

}


.edit-user-item-info{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px #0000001a;
    row-gap: 10px;
}

.from-user-action{
    height: 40px;
    width: 150px;
    border-radius: 50px;
    font-size: 15px;
    background-color: #fff9f0;
    border-color: #41483b;
}

.from-user-action:hover {
    cursor: pointer;
    background-color: #41483b;
    color: white;
} */
