/* Get All Current Cart CSS *************************/

/* .get-current-cart-background-container{
    background: url(https://groundedfullstack.s3.amazonaws.com/grounded_product_images/background-img.jpeg) no-repeat fixed center;
    background-size: cover;

}
*/

.get-current-cart-background-container{
    padding-bottom: 6%;
}


.empty-cart{
    padding-left: 4%;
}

.button-container{
    padding-left: 4%;
}

.cart-blurb {
    font-size: 30px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #88896f;
    padding-left: 4.5%;
    padding-top: 100px;
}

.cart-page-grid{
    display: grid;
    grid-template-rows: 1fr;
}

.checkout-button-container{
    padding-left: 4%;
}

.checkout-button {
    height: 40px;
    width: 150px;
    border-radius: 50px;
    font-size: 15px;
    background-color: #fff9f0;
    border-color: #41483b;

}

.checkout-button:hover {
    cursor: pointer;
    background-color: #41483b;
    color: white;
}


/* EachCartItem CSS ******************************/

.each-cart-item-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    border-bottom:2px solid lightgrey;
    /* padding-left: 4.5%; */

}

.cart-photo-and-title{
    display: grid;
    grid-template-columns: 1fr 2fr;
    /* grid-template-columns: 1fr 1fr 2fr need this for toggle; */
    align-items: center;
    padding-left: 6%;

}

.cart-product-image{
    width: 100px;
    height: 150px;
}

.cart-product-name{
    font-size: 15px;
    align-items: center;
}

.cart-quantity-price-delete{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-self: center;
    justify-items: center;
    justify-content: center;
    align-items: center;
    column-gap: 80px;

}


.cart-price-delete{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-self: right;
    align-items: center;
    column-gap: 40px;
    text-decoration: none;
    border-radius: 50px;

}

.each-product-price{
    padding-top: 0px;
}

.delete-only-button-container{
    justify-self: right;

}

.cart-delete-button{
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background-color: #fff9f0;
    border: none;
    color: lightslategrey;
    cursor: pointer;
}

.cart-delete-button:hover{
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background-color: lightslategrey;
    color: black;
    border: none;
    cursor: pointer;
}


.update-cart-quantity-button {
    height: 40px;
    width: 150px;
    border-radius: 50px;
    font-size: 15px;
    background-color: #fff9f0;
    border-color: #41483b;
}

.update-cart-quantity-button:hover {
    cursor: pointer;
    background-color: #41483b;
    color: white;
}


/* EditQuantityItemModal CSS ******************************/


.modal-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    background-color: #fff9f0;;
    padding-bottom: 15px;
    border-radius: 20px;

}


.edit-cart-image{
    width: 200px;
    height: 250px;
}

.cart-item-name-in-modal{
    text-align: center;
}

.edit-quantity-container{
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    column-gap: 10px;
}

.edit-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }

.edit-button {
    height: 40px;
    width: 150px;
    border-radius: 50px;
    font-size: 15px;
    background-color: #fff9f0;
    border-color: #41483b;
}

.edit-button:hover {
    cursor: pointer;
    background-color: #41483b;
    color: white;
}

/* DeleteCartItemModal CSS ******************************/


/* .modal-content-cart-delete{
    width: 350px;
    height: 100px;
    border-radius: 10px;
} */


#delete-cart-item-modal-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

}

.delete-cart-info{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px #0000001a;
    row-gap: 10px;
}

.from-cart-action{
    height: 40px;
    width: 150px;
    border-radius: 50px;
    font-size: 15px;
    background-color: #fff9f0;
    border-color: #41483b;
}

.from-cart-action:hover {
    cursor: pointer;
    background-color: #41483b;
    color: white;
}
