/* LOGIN PAGE CSS *************************/

body {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }


.login-page-container{
    background: url(https://groundedfullstack.s3.amazonaws.com/grounded_product_images/background-img.jpeg) no-repeat fixed center;
    /* background-repeat: no-repeat;
    background-attachment: fixed; */
    background-size: cover;
}
.login-page-container {
    padding-top: 200px;
    padding-left: 1.5%;
    padding-bottom: 600px;
}

h1 {
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 56px;
    width: 400px;
    height: fit-content;
    padding-top: 130px;
}

.page-login-signup{
    height: 55px;
    width: 130px;
    border-radius: 50px;
    font-size: 15px;
    background-color: none;
}

.shop-button:hover {
    cursor: pointer;
    background-color: #41483b;
    color: white;
}


/* LOGIN MODEL CSS *************************/

#modal-content{
    width: 450px ;
    height: 380px;
    border-radius: 10px;
}
#login-modal-background-test{
    background-color: white;
    width: 450px ;
    height: 380px;
    border-radius: 10px;
}

/* .modal-content-login{
    width: 450px ;
    height: 380px;
    border-radius: 10px;
} */


.login-model-title{
    text-align: center;
    padding-top: 20px;
}

.errors-div{
    font-size: 10px;
    padding-left: 40px;
    color: red;
}

.login-info-label{
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 100%; */

}

.login-modal-info{
    display: flex;
    flex-direction: column;
    justify-items: center;
    row-gap: 10px;
    border: none;
    outline: none;
    padding-top: 20px;
}

.login-info{
    width: 80%;
    height: 30px;
}

.login-model-button-container{
    display: flex;
    justify-content: center;
    /* margin-top: 20px; */
}

.login-model-button{
    width: 80%;
    height: 40px;
    border-radius: 3px;
    border: none;
    background-color: #3e3e3e;
    color: white;
    cursor: pointer;

}

.login-model-sign-up-container{
    display:flex;
    flex-direction: row;
    justify-content:center;
    column-gap: 15px;
    padding-top: 10px;
}

.need-account{
    color: lightslategrey
}

.signup-bottom-button{
    color: lightslategrey;
    cursor: pointer;
}

.signup-bottom-button:hover{
    color: lightslategrey;
    cursor: pointer;
    text-decoration: underline;
}



/* SIGN UP MODEL CSS *************************/

.signup-modal-info{
    display: flex;
    flex-direction: column;
    justify-items: center;
    row-gap: 28px;
    border: none;
    outline: none;
    padding-left: 10px;
}

.sign-up-form-data{
    display: flex;
    width: 100%;
}

.sign-up-info{
    width: 100%;
}

.new-login-info{
    width: 90%;
    height: 30px;
}

.errors {
    color: red;
    font-size: 6pt;
}
